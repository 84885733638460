// import '/assets/styles/partials/eventbox/productbox.scss';
import PropTypes from 'prop-types';
import { useMemo } from 'react'
import classNames from 'classnames';
import types from './types'

export const Eventbox = ({ type = 'regular', className: classNameProp, product, ...props }) => {
	const BoxElem = types[type] ?? types['regular'];

	const className = useMemo(() => {
		return classNames('eventbox', classNameProp);
	}, [classNameProp])

	return <BoxElem
		{...props}
		product={product}
		className={className} />
}

Eventbox.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	preload: PropTypes.bool,
	event: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
}

export default Eventbox