import request from '@wearetla/tla-essentials-tools/utilities/request';

const eventServices = {	
	getEvents: (filters = { page: 1 }) => {
		return new Promise((resolve, reject) => {
			const { term, ...rawFilters} = filters;
			request.get(`event/calendars`, { ...(term ? { query: term } : {}), ...rawFilters }, (payload, status, request) => {
				if(payload.events) {
					resolve({
						events: payload.events,
						pagination: request.data.pagination,
					});
				}
				else {
					reject(request);
				}
			});
		});
	},
	old_getEvents: (filters = { page: 1 }) => {
		return new Promise((resolve, reject) => {
			const { term, ...rawFilters} = filters;
			request.get(`event/filter`, { ...(term ? { query: term } : {}), ...rawFilters }, (payload, status, request) => {
				if(payload.events) {
					resolve({
						events: payload.events,
						pagination: request.data.pagination,
					});
				}
				else {
					reject(request);
				}
			});
		});
	},
	getEvent: (eventId) => {
		return new Promise((resolve, reject) => {
			request.get(`event/detail/${eventId}`, {}, (payload, status, request) => {
				if (payload?.event) {
					resolve(payload.event);
				}
				else {
					reject(request);
				}
			});
		});
	},
}

export default eventServices;